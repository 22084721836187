<template>
  <ListPageContainer :title="$t('announce.places')">
    <div class="filters">
      <ItemsListFilter v-if="currentTagsSet.length > 0"
                       :current-filters="currentTagsSet"
                       :active-filter="activeCategoryIndex"
                       @filter="filterByCategory"
      />
    </div>

    <div v-if="currentDataSet.length > 0">
      <PlacesMap :data-set="currentDataSet"
                 :categories-set="currentTagsSet"
                 :coords="GET_PANEL.location"
      />
    </div>

    <r-row class="px-4" v-if="currentDataSet.length > 0 && !loading">
      <r-col v-for="item in currentDataSet" :key="item.id" :cols="{ widest: 4, wide: 4, middle: 2, narrow: 2 }">
        <LazyAnnouncement :data="item" @goto="goToItem(item)" />
      </r-col>
    </r-row>

    <NothingFound v-else-if="currentDataSet.length === 0 && !loading"/>

    <ListLoading :items="3" v-else/>
  </ListPageContainer>
</template>

<script>
import {mapGetters} from "vuex";
import ListPageContainer from "@/components/Containers/ListPageContainer";
import ItemsListFilter from "@/components/Filters/ItemsListFilter";
import ListLoading from "@/components/Miscellaneous/ListLoading";
import NothingFound from "@/components/Miscellaneous/NothingFound";
import LazyAnnouncement from "@/components/LazyAnnouncement/LazyAnnouncement";
import PlacesMap from "@/components/Map/PlacesMap";

export default {
  name: "PlacesListView",

  components: {
    PlacesMap,
    LazyAnnouncement,
    //Map,
    //DetailedPageMap,
    ListPageContainer,
    ItemsListFilter,
    NothingFound,
    ListLoading,
  },

  computed: {
    ...mapGetters(['GET_PLACES', 'GET_PLACES_CATEGORIES', 'GET_FILTERED_PLACES', 'GET_PANEL', 'GET_SINGLE_CITY']),

    currentDataSet() {
      if (this.isFiltered) {
        return this.GET_FILTERED_PLACES;
      }
      return this.GET_PLACES;
    },

    currentTagsSet() {
      return this.$makeCategoryTagsSet(this.GET_PLACES_CATEGORIES);
    },

    panelCoords() {
      return {
        longitude: this.GET_PANEL.longitude,
        latitude: this.GET_PANEL.latitude,
        radius: this.$currentConfig.placesRadius
      }
    },

    singleCityId() {
      return {
        cityId: this.GET_SINGLE_CITY ? this.GET_SINGLE_CITY[0].id : ''
      }
    }
  },

  data() {
    return {
      isFiltered: null,
      loading: false,
      activeCategoryIndex: 0,
      filterCategoryId: null,
    }
  },

  methods: {
    filterByCategory({id, index}) {
      if (this.activeCategoryIndex === index) return;

      this.loading = true;
      this.activeCategoryIndex = index;
      this.filterCategoryId = id;

      if (id === 'all') {
        this.filterCategoryId = null;
      }

      const options = this.panelCoords;
      options.cityId = this.singleCityId?.cityId;

      this.$store.dispatch('GET_FILTERED_PLACES_FROM_SERVER',
          { category: this.filterCategoryId, ...options }).finally(() => {
        this.loading = false;
        this.isFiltered = true;
      });
    },

    goToItem(item) {
      this.$router.push({ path: `/places/${item.id}` });
    },
  },
}
</script>

<style lang="scss">
.filters {
  margin-bottom: 24px;
}
</style>
