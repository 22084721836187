<template>
  <r-row class="px-4">
    <r-col :cols="{ widest: 4, wide: 4, middle: 6, narrow: 6 }">
      <div class="places-map">
        <l-map
            :zoom="zoom"
            :center="mapCenter"
            :options="{attributionControl: false, zoomControl: false}"
            :crs="crs"
            ref="map"
        >
          <l-tile-layer
              :url="url"
              :attribution="attribution"
          />

          <l-marker
              v-for="item in pins"
              :key="item.id"
              :lat-lng="item.location"
              :icon="markerIcon(item)"
              :zIndexOffset="500">
            <l-popup :options="{closeButton: false, offset: [0, -24]}">
              <div class="popup__title roquefort harakiri--text">
                {{ item.title }}
              </div>
              <div class="popup__desc сaprino troy--text">

              </div>
              <a :href="`/places/${item.id}`" class="popup__link briscola rocky--text">
                {{ $t('misc.more') }}
              </a>
            </l-popup>
          </l-marker>

          <l-control-attribution :position="'bottomright'" :prefix="false"/>

          <l-control class="example-custom-control" :position="'bottomright'">
            <MapControls
                @zoomin="zoomIn"
                @zoomout="zoomOut"
                @setlocation="locateCenter"
            />
          </l-control>
        </l-map>
      </div>
    </r-col>
  </r-row>
</template>

<script>
import L, {CRS, icon} from 'leaflet';
import {LControlAttribution, LMap, LTileLayer, LControl, LMarker, LPopup} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import MapControls from "@/components/TransportMap/MapControls/MapControls";

export default {
  name: "PlacesMap",

  components: {
    MapControls,
    LMap,
    LTileLayer,
    LControl,
    LMarker,
    LControlAttribution,
    LPopup,
  },

  props: {
    dataSet: {
      type: [Object, Array],
      default: () => [],
    },
    categoriesSet: {
      type: [Object, Array],
      default: () => [],
    },
    coords: {
      type: [Array, String],
      default: () => [],
    }
  },

  watch: {
    dataSet: function() {
      this.fitBounds();
    }
  },

  mounted() {
    this.fitBounds();
  },

  computed: {
    pins() {
      return this.$makePlacesPins(this.dataSet, this.categoriesSet);
    },
  },

  data() {
    return {
      url: 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.07.28-0-b210701140430&x={x}&y={y}&z={z}&scale=2&lang=ru_RU',
      attribution:
          `
          <div style="display: flex; align-items: center; font-size: 11px;">© Яндекс
          <a style="margin-left: 4px; color: black; font-size: 11px;" href='https://yandex.ru/legal/maps_termsofuse/?lang=ru'>Условия использования</a>
          <img style="margin: 0 4px; height: 14px;" src="/img/map/yandex-logo.svg"/></div>
        `,
      crs: CRS.EPSG3395,
      zoom: 14,
      mapCenter: this.coords ? this.coords : [],
    }
  },

  methods: {
    zoomIn() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomIn();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    zoomOut() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.zoomOut();
      this.zoom = mapComponent.mapObject.getZoom();
    },

    locateCenter() {
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.setView(this.mapCenter, 10);
    },

    markerIcon(item) {
      return icon({
        iconUrl: `/img/map/pins/icon-${item.color}.svg`,
        iconSize: [24, 36],
        iconAnchor: [12, 36],
      });
    },

    fitBounds() {
      let arrayOfMarkers = [];
      for (let i = 0; i < this.dataSet.length; i++) {
        arrayOfMarkers.push([this.dataSet[i].location[0], this.dataSet[i].location[1]])
      }
      var bounds = new L.LatLngBounds(arrayOfMarkers);
      const mapComponent = this.$refs.map;
      mapComponent.mapObject.fitBounds(bounds);
    }
  }
}
</script>

<style lang="scss">
.places-map {
  height: 276px;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  margin-bottom: 16px;

  .popup {

    &__link {
      text-decoration: none;
    }
  }

  .leaflet-popup-tip-container {
    display: none;
  }
  .leaflet-popup-content-wrapper {
    box-shadow: 0px 8px 32px rgba(12, 16, 24, 0.12);
  }
  .leaflet-popup-content {
    margin: 12px
  }
}

</style>
